import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import {
  useCallback,
  useEffect,
  useRef
} from 'react';
import { Stanza } from '@/interfaces/XMPP';
import JXON from 'jxon';
import {
  clear,
  getAllMessages,
  IFileMessage,
  IImageMessage,
  IMessage,
  IVideoMessage,
  remove,
  update as updateMessages,
  add as addMessages,
  setReply,
  IAudioMessage,
  update,
  isMessage
} from '@/store/messages/messages';
import {
  DeleteNotifyMessage,
  ISentMessageWithReply,
  RoomUsersUpdatedNotify
} from '@/interfaces/YC';
import { AnyObject } from '@/index';
import { getSender } from '@/utils/messages';
import { getUser } from '@/store/user/user';
import Messages from '@/services/Messages';
import {
  addFiles,
  Chat,
  removeFile,
  removeMembers
} from '@/store/chats/chats';
import { AxiosProgressEvent } from 'axios/index';
import { v4 as uuid } from 'uuid';
import {
  uploadAudio,
  uploadFile,
  uploadImage,
  uploadVideo
} from '@/api/upload';
import useChatMembers from '@/hooks/useChatMembers';
import { isFileMessage } from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import connection from '@/services/Connection/Connection';
import {
  show as showNotify,
  show
} from '@/components/Modal/UserNotify/UserNotify';
import { useIntl } from 'react-intl';
import { pushApp } from '@/api/push-app';
import { isRoomMessage } from '@/utils/chats';
import useActiveService from '@/hooks/useActiveService';
import { CustomEvents } from '@/interfaces/general';

const USER_MESSAGE_TIMEOUT = 5000

const checkFieldInMessage = (fields: string[], msg: AnyObject) => {
  return fields.reduce((acc, field) => acc || field in msg, false)
}

const createMessageEvent = () => {
  return new CustomEvent(CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT)
}

export const dispatchMessageEvent = () => {
  window.dispatchEvent(createMessageEvent())
}

const useMessage = ({ watch = false } = {}) => {
  const messages = useAppSelector(getAllMessages)
  const user = useAppSelector(getUser)
  const { updateMembers } = useChatMembers()
  const dispatch = useAppDispatch()
  const messageReceivedRef = useRef<(msg: Stanza) => boolean>()
  const { formatMessage } = useIntl()
  const {setActiveChatId} = useActiveService()

  const updateMessageState = useCallback((message: AnyObject) => {
    const { from } = getSender(message as ISentMessageWithReply)
    const uid = (message.displayed || message.received)?.$id
    const msg = messages[from]?.messages
      .filter(isMessage)
      .find(message => message.id === uid)
    if (msg) {
      const status = message.displayed ? 'displayed' :
        message.received && msg.status !== 'displayed' ? 'received' : null
      const timestamp = message.received || message.displayed ?
        (message.received || message.displayed).$timestamp : null
      const newMessage: IMessage = {
        ...msg,
      }
      if (status) {
        newMessage.status = status
      }
      if (timestamp) {
        newMessage.timestamp = +timestamp
      }
      dispatch(updateMessages({ jid: from, messages: [newMessage] }))
    }
  }, [messages, dispatch])

  const handleClearMessage = useCallback((message: DeleteNotifyMessage) => {
    const { from } = getSender(message)
    dispatch(clear(from))
  }, [dispatch])

  const notifyReceivedMessage = useCallback((message: IMessage) => {
    const msg = Messages.Messages.createNotifyMessageReceived({
      from: message.to,
      to: message.from,
      messageId: message.id,
      timestamp: message.timestamp,
    })
    connection.sendStrophe(msg)
  }, [])

  const updateGroupMembers = useCallback((message: RoomUsersUpdatedNotify) => {
    const { from } = getSender(message)

    switch (message.roomUsers.$action) {
      case 'delete':
        const users = message.roomUsers.user
        const memberJids = Array.isArray(users) ? users : [users]
        dispatch(removeMembers({ chatJid: from, memberJids }))
        break;
      case 'add':
        updateMembers(from)
        break
    }

  }, [updateMembers, dispatch])

  const addNewMessage = useCallback((message: ISentMessageWithReply) => {
    const { from } = getSender(message)
    const oldMessage = messages[from === user?.$jid ? message.$to : from]?.messages
      .find(msg => msg.id === message.yc.info.$uid)
    if (oldMessage) {
      updateMessageState(message)
      return
    }
    const newMessage = Messages.Messages.parseMessage(message, { status: 'received' })
    if (from !== user?.$jid && !isRoomMessage(newMessage)) {
      notifyReceivedMessage(newMessage)
    }
    const jid = from === user?.$jid ? message.$to : from
    dispatch(updateMessages({ jid, messages: [newMessage] }))
    if (isFileMessage(newMessage)) {
      dispatch(addFiles({
        chatJid: jid,
        files: [{
          uid: newMessage.id,
          url: newMessage.file.url,
          name: newMessage.file.name,
        }],
        before: true,
      }))
    }
  }, [dispatch, user?.$jid, notifyReceivedMessage, messages, updateMessageState])

  messageReceivedRef.current = useCallback((msg: Stanza) => {
    const stanza: AnyObject = JXON.stringToJs(msg.outerHTML)
    console.log('message handler: ', stanza)  // eslint-disable-line no-console
    const stanzaMsg = stanza.message.result?.forwarded?.message ?
      stanza.message.result.forwarded :
      stanza.message?.event?.items?.item ? stanza.message?.event?.items?.item :
        stanza
    if (!stanzaMsg.message) {
      return true
    }
    const { from } = getSender(stanzaMsg.message)
    const message = messages[from]?.messages.find(message => message.id === stanzaMsg.message.$id)

    if (stanzaMsg?.message?.roomUsers) {
      updateGroupMembers(stanzaMsg.message)
    } else if (stanzaMsg?.message?.remove) {
      const messageDelete = messages[from].messages
        .filter(isMessage)
        .find(message => message.id === stanzaMsg.message.remove.$id)
      if (!!messageDelete) {
        dispatch(remove({ jid: from, message: messageDelete }))
      } else {
        dispatch(removeFile({ chatJid: from, uid: stanzaMsg.message.remove.$id }))
      }
    } else if (stanzaMsg?.message?.delete) {
      handleClearMessage(stanzaMsg.message)
    } else if (!!message || checkFieldInMessage(['received', 'displayed'], stanzaMsg?.message)) {
      updateMessageState(stanzaMsg.message)
    } else if (stanzaMsg.message?.sent?.forwarded?.message?.yc?.$xmlns === 'urn:yc:message:data') {
      // message from myself from another device
      addNewMessage(stanzaMsg.message.sent.forwarded.message)
    } else if (checkFieldInMessage(['error'], stanzaMsg?.message)) {
      return true
    } else if (stanzaMsg.message?.yc?.$xmlns === 'urn:yc:message:data') {
      addNewMessage(stanzaMsg.message)
    }
    return true
  }, [
    updateMessageState,
    addNewMessage,
    handleClearMessage,
    updateGroupMembers,
    messages,
    dispatch,
  ])

  useEffect(() => {
    if (!watch) {
      return
    }

    const handler = (stanza: Element) => {
      try {
        messageReceivedRef.current?.(stanza)
      } catch (e) {
        console.error(e) // eslint-disable-line no-console
      }
      return true
    }
    const refHandler = connection.addHandler(handler, '', 'message')
    return () => {
      connection.deleteHandler(refHandler)
    }
  }, [watch])


  const sendMessage = useCallback((msg: Element, addToChat: boolean = true) => {
    connection.sendStrophe(msg)
    const msgJson = JXON.xmlToJs(msg) as ISentMessageWithReply
    pushApp(msgJson.$to, msgJson.$type)
    if (addToChat) {
      const formatMessage: IMessage = Messages.Messages.parseMessage(msgJson)
      dispatch(updateMessages({ jid: formatMessage.to, messages: [formatMessage] }))
    }
    return connection.isConnected
  }, [dispatch]);

  const forwardMessage = useCallback((forwardedMessage: IMessage, chats: Chat[]) => {
    const userJid = user?.$jid
    if (!userJid || !forwardedMessage) {
      return
    }
    chats.forEach(chat => {
      const message = Messages.Messages.createForwardMessage({
        from: userJid,
        to: chat.$jid,
        message: forwardedMessage,
        isRoom: chat.type === 'groupchat',
        forwardedFrom: forwardedMessage.from,
      })
      sendMessage(message)
      if (isFileMessage(forwardedMessage)) {
        dispatch(addFiles({
          chatJid: chat.$jid,
          files: [{
            name: forwardedMessage.file.name,
            url: forwardedMessage.file.url,
            uid: forwardedMessage.id,
          }],
          before: true,
        }))
      }
    })
    if (chats.length === 1) {
      setActiveChatId(chats[0].$jid)
    }
  }, [sendMessage, user?.$jid, dispatch, setActiveChatId])

  interface SendFileProps {
    file: File,
    userJid: string,
    toChat: Chat,
    replyMessage?: IMessage
  }

  const sendFile = useCallback(async(
    {
      file,
      userJid,
      toChat,
      replyMessage,
    }: SendFileProps) => {
    if (!userJid) {
      throw new Error('Current user not found')
    }
    const uid = uuid()
    const fileMessage: IFileMessage = {
      from: userJid,
      to: toChat.$jid,
      id: uid,
      type: 'file',
      status: 'sending',
      file: {
        mimetype: file.type,
        name: file.name,
        size: file.size,
        token: 'uid',
        url: URL.createObjectURL(file),
      },
      replyMessage,
      timestamp: new Date().getTime() * 1000,
      uploaded: 0,
      downloaded: null,
    }
    const jid = toChat.$jid
    dispatch(updateMessages({ jid, messages: [fileMessage] }))
    const onUploadProgress = (e: AxiosProgressEvent) => {
      const progress = Math.round(e.loaded / (e.total || e.loaded) * 100)
      const progressFileMessage: IFileMessage = {
        ...fileMessage,
        downloaded: progress,
      }
      dispatch(updateMessages({ jid, messages: [progressFileMessage] }))
    }
    let result
    try {
      result = await uploadFile({ file, uid, onUploadProgress })
    } catch (e) {
      show({
        message: formatMessage({ id: 'file_was_not_sent_out' }),
        timeout: USER_MESSAGE_TIMEOUT
      })
      dispatch(remove({ jid, message: fileMessage }))
      console.error(e) // eslint-disable-line no-console
      return
    }
    const fileStanza = Messages.Messages.createFileMessage({
      from: userJid,
      to: toChat.$jid,
      isRoom: toChat?.type === 'groupchat',
      thread: uid,
      uid,
      file: {
        ...fileMessage.file,
        ...result,
      },
      replyMessage,
    })
    sendMessage(fileStanza, false)
    const updatedFileMessage: IFileMessage = {
      ...fileMessage,
      status: 'sent',
      file: {
        ...fileMessage.file,
        ...result,
      },
    }
    dispatch(updateMessages({ jid: toChat.$jid, messages: [updatedFileMessage] }))
    dispatch(addFiles({
      chatJid: toChat.$jid,
      files: [{
        uid,
        url: updatedFileMessage.file.url,
        name: updatedFileMessage.file.name,
      }],
      before: true,
    }))
  }, [dispatch, sendMessage, formatMessage])

  const sendVideo = useCallback(async(
    {
      file,
      userJid,
      toChat,
      replyMessage,
    }: SendFileProps) => {
    if (!userJid) {
      throw new Error('Current user not found')
    }
    const uid = uuid()
    const videoMessage: IVideoMessage = {
      from: userJid,
      to: toChat.$jid,
      id: uid,
      type: 'video',
      status: 'sending',
      url: '',
      name: file.name,
      replyMessage,
      timestamp: new Date().getTime() * 1000,
      uploaded: 0,
    }
    const jid = toChat.$jid
    dispatch(updateMessages({ jid, messages: [videoMessage] }))
    const onUploadProgress = (e: AxiosProgressEvent) => {
      const progress = Math.round(e.loaded / (e.total || e.loaded) * 100)
      const progressVideoMessage: IVideoMessage = {
        ...videoMessage,
        uploaded: progress,
      }
      dispatch(updateMessages({ jid, messages: [progressVideoMessage] }))
    }
    let result
    try {
      result = await uploadVideo({ file, uid, onUploadProgress })
    } catch (e) {
      show({
        message: formatMessage({ id: 'file_was_not_sent_out' }),
        timeout: USER_MESSAGE_TIMEOUT
      })
      dispatch(remove({ jid, message: videoMessage }))
      console.error(e) // eslint-disable-line no-console
      return
    }
    const fileStanza = Messages.Messages.createVideoMessage({
      from: userJid,
      to: toChat.$jid,
      isRoom: toChat?.type === 'groupchat',
      thread: uid,
      uid,
      url: result.url,
      thumbnail: result.thumbnail,
      name: videoMessage.name,
      replyMessage,
    })
    sendMessage(fileStanza, false)
    const updatedVideoMessage: IVideoMessage = {
      ...videoMessage,
      status: 'sent',
      url: result.url,
      thumbnail: result.thumbnail,
    }
    dispatch(updateMessages({ jid: toChat.$jid, messages: [updatedVideoMessage] }))
  }, [dispatch, sendMessage, formatMessage])

  const sendImage = useCallback(async(
    {
      file,
      userJid,
      toChat,
      replyMessage,
    }: SendFileProps) => {
    const uid = uuid()
    const fileUrl = URL.createObjectURL(file)
    const reply = replyMessage
    const message: IImageMessage = {
      id: uid,
      from: userJid,
      to: toChat.$jid,
      type: 'image',
      status: 'sending',
      timestamp: new Date().getTime() * 1000,
      image: {
        url: fileUrl,
        thumbnail: fileUrl,
      },
      ...(reply ? { replyMessage: reply } : null),
    }
    dispatch(addMessages({ jid: toChat.$jid, message }))
    dispatch(setReply({ jid: toChat.$jid, replyMessage: null }))
    try {
      const { url, thumbnail } = await uploadImage({ file, uid })
      const stanza = Messages.Messages.createImageMessage(
        {
          from: userJid,
          to: toChat.$jid,
          isRoom: toChat.type === 'groupchat',
          thread: uid,
          uid,
          url,
          thumbnail,
          replyMessage: reply,
        },
      )
      if (sendMessage(stanza, false)) {
        const imageMessage: IImageMessage = {
          ...message,
          status: 'sent',
          image: {
            url,
            thumbnail,
          },
        }
        const image = new Image()
        image.src = thumbnail
        image.onload = () => {
          dispatch(updateMessages({ jid: toChat.$jid, messages: [imageMessage] }))
        }
      } else {
        throw new Error('Message not sent')
      }
    } catch (e) {
      dispatch(remove({
        jid: toChat.$jid, message
      }))
    }
  }, [dispatch, sendMessage])

  interface SendAudioProps extends Omit<SendFileProps, 'file'> {
    file: Blob | File
  }

  const sendAudio = useCallback(async(
      {
        file,
        userJid,
        toChat,
        replyMessage,
      }: SendAudioProps
    ) => {
    const uid = uuid()
    const reply = replyMessage
    const to =toChat.$jid
    const audioMessage: IAudioMessage = {
    from: userJid,
    to,
    id: uid,
    type: 'audio',
    status: 'sending',
    url: '',
    ...(file instanceof File && {name: file.name}),
    timestamp: new Date().getTime() * 1000,
    ...(reply && {replyMessage: reply}),
  }
  dispatch(update({jid: to, messages: [audioMessage]}))
  dispatch(setReply({jid: to || '', replyMessage: null}))
  dispatchMessageEvent()
  try {
    const {url} = await uploadAudio({uid, blob: file})
    const msg = Messages.Messages.createAudioMessage({
      from: userJid,
      to,
      isRoom: toChat.type === 'groupchat',
      uid,
      url,
      ...(file instanceof File && {name: file.name}),
      replyMessage: reply,
    })
    sendMessage(msg, false)
    const sentMessage: IAudioMessage = {
      ...audioMessage,
      status: 'sent',
      url,
    }
    dispatch(update({jid: to, messages: [sentMessage]}))
  } catch (e) {
    showNotify({
      message: formatMessage({id: 'file_was_not_sent_out'}),
      timeout: USER_MESSAGE_TIMEOUT,
    })
    dispatch(remove({jid: to, message: audioMessage}))
    console.error(e) // eslint-disable-line no-console
  }
  }, [dispatch, formatMessage, sendMessage])


  return { sendMessage, forwardMessage, sendFile, sendVideo, sendImage, sendAudio }
}

export default useMessage
