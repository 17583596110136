import {FormattedMessage, useIntl} from 'react-intl';
import PhotoEditor from '@/components/PhotoEditor/PhotoEditor';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {
  Chat,
  getActiveChatId,
  getChat, getPrivateChats, GroupCategoriesMap,
  IGroupChatMember, removeMembers,
  updateChat, updateMembers,
} from '@/store/chats/chats';
import Field from '@/components/Primitive/Field/Field';
import {ReactComponent as StarEmptyImage} from '@/images/icons/star-empty.svg';
import {ReactComponent as StarImage} from '@/images/icons/star-fill.svg';
import {ReactComponent as ClearImage} from '@/images/icons/clear.svg';
import {ReactComponent as TrashImage} from '@/images/icons/trash.svg';
import {ReactComponent as BlockImage} from '@/images/icons/block.svg';
import {ReactComponent as DividerImage} from '@/images/icons/divider.svg';
import {ReactComponent as SettingsImage} from '@/images/icons/settings.svg';
import {
  checkOwner,
  contactListToArray,
  getApprovedChats,
  getChatId,
  isTeamChannel,
  isMyTeam,
  isChannelConsultant, isChannelCustomer, getNameOrNick,
} from '@/utils/chats';
import {
  ComponentProps,
  FocusEventHandler, forwardRef,
  MouseEventHandler,
  useCallback,
  useEffect, useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import {checkUserBlocked} from '@/store/blockList/blockList';
import usePresence from '@/hooks/usePresence';
import Confirm from '@/components/Modal/Confirm/Confirm';
import {useModal} from '@/hooks/useModal';
import {getUser, IUserVCardKeys} from '@/store/user/user';
import {
  addToRoom,
  clearAllMessages,
  IVcard,
  setMUChatAvatar,
  setNickName,
} from '@/api/chats';
import {clear} from '@/store/messages/messages';
import {getOrCreateArray} from '@/utils/array';
import Messages from '@/services/Messages';
import styles from '@/components/Modal/ChatInfo/ChatInfo.module.scss';
import modalTitleStyles from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle.module.scss'
import titleStyles from '@/components/Modal/ModalTitle/ModalTitle.module.scss'
import VCard from '@/services/VCard';
import Presence from '@/services/Presence';
import {show as showNotify} from '@/components/Modal/UserNotify/UserNotify';
import useUtils from '@/hooks/useUtils';
import classNames from 'classnames';
import Avatar from '@/components/Avatar/Avatar';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import useContextMenuList from '@/components/ContextMenu/ContextMenuList/useContextMenuList';
import ContextMenuList,
{ContextMenuProps, IContextMenuList} from '@/components/ContextMenu/ContextMenuList/ContextMenuList';
import TabPanel, {Tab} from '@/components/TabPanel/TabPanel';
import Participants from '@/components/Participants/Participants';
import useModalSimple from '@/hooks/useModalSimple';
import SelectChatsModal from '@/components/Modal/SelectChatsModal/SelectChatsModal';
import ChatFiles from '@/components/ChatFiles/ChatFiles';
import GroupChatType from '@/components/Modal/GroupChatType/GroupChatType';
import {ErrorFactory} from '@/services/errorFactory';
import useChatActions from '@/hooks/useChatActions';
import {getChatsCache} from '@/store/cache/chats';
import {getScreenWidth} from '@/store/screenSize/screenSize';
import {getFirstChar} from '@/utils/text';
import TabModal, {ITabModal} from '@/components/Modal/TabModal/TabModal';
import {IClass} from '@/interfaces/general';
import {IChatMember} from '@/components/Participants/ParticipantItem/ParticipantItem';
import connection from '@/services/Connection/Connection';
import useActiveService from '@/hooks/useActiveService'

export interface ChatInfoProps extends IClass {
}

const MENU_ACTIONS = {
  CLEAR_ALL_MESSAGES: 'clearAllMessages',
  DELETE_AND_LEAVE_CHAT: 'deleteAndLeaveChat',
  BLOCK_CONTACT: 'blockContact',
  UNBLOCK_CONTACT: 'unblockContact',
  DELETE_USER: 'deleteUser',
  MUTE_CHAT: 'muteChat',
  UNMUTE_CHAT: 'unmuteChat',
} as const

const TABS_ACTIONS = {
  PARTICIPANTS: 'participants',
  FILES: 'files',
}

const MAX_AVATAR_SIZE = 400
const CONTAINER_PADDINGS = 24

const ChatInfo = forwardRef<ITabModal, ChatInfoProps>(({className}: ChatInfoProps, ref) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const screenWidth = useAppSelector(getScreenWidth)
  const privateChats = useAppSelector(getPrivateChats)
  const activeChatJid = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatJid || ''))
  const chatCache = useAppSelector(getChatsCache)
  const [cachedChat, setCachedChat] = useState<Chat | null>(activeChat ? {
    ...chatCache[activeChat.$jid],
    ...activeChat,
  } : null)
  const {formatMessage} = useIntl()
  const {blockContact, unblockContact} = usePrivateChatList()
  const userBlocked = useAppSelector(checkUserBlocked(cachedChat?.$jid || ''))
  const [isBlocked, setIsBlocked] = useState(userBlocked)
  const [isFavorite, setIsFavorite] = useState(activeChat?.groups?.includes(GroupCategoriesMap.favorite))
  const [members, setMembers] = useState(getOrCreateArray<IGroupChatMember>(cachedChat?.members))
  const [notMembers, setNotMembers] = useState<Chat[]>([])
  const [isOwner, setIsOwner] = useState(checkOwner(user?.$jid || '', members))
  const [bigPhoto, setBigPhoto] = useState(false)
  const [canManage, setCanManage] = useState(!isMyTeam(cachedChat?.groups || []))
  const [allowPhotoEdit, setAllowPhotoEdit] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(cachedChat?.vcard?.thumbnail)
  const [actionMenuActive, setActionMenuActive] = useState(false)
  const [tabs, setTabs] = useState<Tab[]>([
    ...(activeChat?.type === 'groupchat' ? [
      {
        name: formatMessage({id: 'CHAT.PARTICIPANTS'}),
        action: TABS_ACTIONS.PARTICIPANTS,
      },
    ] : []),
    {name: formatMessage({id: 'CHAT.FILES'}), action: TABS_ACTIONS.FILES},
  ])
  const [activeTab, setActiveTab] = useState(activeChat?.type === 'groupchat' ?
    TABS_ACTIONS.PARTICIPANTS : TABS_ACTIONS.FILES)
  const contextBoxRef = useRef<HTMLDivElement>(null)
  const tabModalRef = useRef<ITabModal>(null)
  const {unsubscribe} = usePresence()
  const {removeContact} = usePrivateChatList()
  const {toggleFavoriteUtils} = useUtils()
  const {muteChat, leaveChat} = useChatActions()
  const {setActiveChatId} = useActiveService()

  useEffect(() => {
    setCanManage(!isMyTeam(cachedChat?.groups || []))
  }, [cachedChat?.groups]);

  const {
    show: showInviteParticipants,
    hide: hideInviteParticipants,
    visible: visibleInviteParticipants,
  } = useModalSimple()

  const {show: showTypeChat, hide: hideTypeChat, visible: visibleTypeChat} = useModalSimple()

  const onActionMenuHide = useCallback(() => {
    setActionMenuActive(false)
  }, [])

  const {show: showActionMenu, hide: hideActionMenu, ContextMenu: ActionMenu} = useContextMenuList<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
    onHide: onActionMenuHide,
  })

  const hide = () => {
    tabModalRef.current?.hide()
  }

  useImperativeHandle(ref, () => ({
    show() {
      tabModalRef.current?.show()
    },
    hide() {
      tabModalRef.current?.hide()
    },
  }))


  useEffect(() => {
    if (!activeChat) {
      tabModalRef.current?.hide()
    }
  }, [activeChat]);

  useEffect(() => {
    setCachedChat(prevState => {
      if (!activeChat) {
        return activeChat
      }
      if (activeChat.$jid === prevState?.$jid) {
        return {
          ...prevState,
          ...activeChat,
        }
      }
      return {
        ...chatCache[activeChat?.$jid],
        ...activeChat,
      }
    })
  }, [activeChat, chatCache])

  useEffect(() => {
    const membersJids = new Set(cachedChat?.members?.map(member => member.jid) || [])
    setNotMembers(getApprovedChats(contactListToArray(privateChats))
      .filter(member => !membersJids.has(member.$jid)))
  }, [privateChats, cachedChat?.members])

  useEffect(() => {
    const hide = () => {
      setTimeout(hideActionMenu, 0)
    }
    if (actionMenuActive) {
      document.addEventListener('mouseup', hide)
    }

    return () => {
      document.removeEventListener('mouseup', hide)
    }
  }, [actionMenuActive, hideActionMenu])

  useEffect(() => {
    setTabs([
      ...(activeChat?.type === 'groupchat' ? [
        {
          name: formatMessage({id: 'CHAT.PARTICIPANTS'}),
          action: TABS_ACTIONS.PARTICIPANTS,
        },
      ] : []),
      {name: formatMessage({id: 'CHAT.FILES'}), action: TABS_ACTIONS.FILES},
    ])
  }, [activeChat?.type, formatMessage])

  useEffect(() => {
    const url = cachedChat?.vcard?.url
    const thumbnail = cachedChat?.vcard?.thumbnail
    let newUrl = ''
    if (bigPhoto && avatarUrl !== url) {
      if (!url) {
        setAvatarUrl(undefined)
        return
      }
      newUrl = url
    } else if (!bigPhoto && avatarUrl !== thumbnail) {
      if (!thumbnail) {
        setAvatarUrl(undefined)
        return
      }
      newUrl = thumbnail
    }
    if (!newUrl) {
      return
    }

    const image = new Image()
    image.onload = () => {
      setAvatarUrl(newUrl)
    }
    image.src = newUrl
  }, [bigPhoto, cachedChat?.vcard, avatarUrl])

  useEffect(() => {
    setMembers(getOrCreateArray<IGroupChatMember>(cachedChat?.members))
  }, [cachedChat?.members])

  useEffect(() => {
    setIsOwner(checkOwner(user?.$jid || '', members))
  }, [members, user?.$jid])

  const confirmModalRef = useCallback(
    ({...props}: ComponentProps<typeof Confirm>) => <Confirm
      {...props}
    />,
    [])

  const {show: showConfirm, Modal: ConfirmModal} = useModal(confirmModalRef)

  const unsubscribeHandler = (withConfirm = true) => {
    if (!cachedChat) {
      return
    }
    const deleteUser = () => {
      unsubscribe(cachedChat.$jid)
      removeContact(cachedChat.$jid)
      if (activeChat?.$jid === activeChatJid) {
        setActiveChatId(null)
      }
      hide?.()
    }
    if (withConfirm) {
      showConfirm({
        title: formatMessage({id: 'CONTACTS.DELETE_CONTACT'}),
        confirmTitle: formatMessage({id: 'ACTION.DELETE'}),
        cancelTitle: formatMessage({id: 'ACTION.CANCEL'}),
        description: formatMessage({id: 'CONTACTS.USER_DELETE_ASK'},
          {
            name: getNameOrNick(cachedChat),
          }),
        onConfirm: deleteUser,
      })
    } else {
      deleteUser()
    }

  }

  const toggleFavorites = async () => {
    if (!activeChat || !user?.$jid) {
      return
    }

    setIsFavorite(prevState => !prevState)
    try {
      await toggleFavoriteUtils(activeChat, user, !!isFavorite)
    } catch (e) {
      setIsFavorite(isFavorite)
    }
  }

  const toggleBlockContact = async () => {
    if (!cachedChat) {
      return
    }
    const blocked = !isBlocked
    if (blocked) {
      const blockUser = async () => {
        unsubscribeHandler(false)
        const promise = blockContact(cachedChat.$jid)
        setIsBlocked(blocked)
        await promise
          .catch(() => {
            setIsBlocked(!blocked)
          })
      }
      showConfirm({
        title: formatMessage({id: 'CONFIRM.TITLE'}),
        description: formatMessage({id: 'block_contact_warning'}),
        onConfirm: blockUser,
      })
    } else {
      const promise = unblockContact(cachedChat.$jid)
      setIsBlocked(blocked)
      await promise.catch(() => {
        setIsBlocked(!blocked)
      })
    }
  }

  const handleLeaveChat = () => {
    const leaveChatAndHide = async () => {
      leaveChat(cachedChat)
        .finally(() => {
          hide()
        })
    }
    showConfirm({

      description: formatMessage({id: 'GROUP_CHAT.ASK_BEFORE_DELETE'}),
      onConfirm: leaveChatAndHide,
    })
  }

  const handleClearAllMessage = () => {
    if (!cachedChat) {
      return
    }
    const clearChatMessages = async () => {
      try {
        await clearAllMessages(getChatId(cachedChat.$jid))
        const msg = Messages.Messages.createNotifyClearChatMessage({
          from: user?.$jid || '',
          to: cachedChat.$jid,
        })
        connection.sendStrophe(msg)
        dispatch(clear(cachedChat.$jid))
      } catch (e) {

      }
    }
    showConfirm({
      description: formatMessage({id: 'GROUP_CHAT.ASK_BEFORE_DELETE_ALL_MESSAGE'}),
      onConfirm: clearChatMessages,
    })
  }

  const deleteAvatar = useCallback(async () => {
    if (cachedChat?.vcard) {
      const fields: IVcard = {
        ...cachedChat.vcard,
      }
      const excludedFields: IUserVCardKeys[] = ['url', 'thumbnail']
      excludedFields.forEach(key => {
        delete fields[key]
      })
      const msg = VCard.Messages.setVCard({
        ...fields,
        to: cachedChat.$jid,
      })
      try {
        await connection.send(msg)
        dispatch(updateChat({
          chatJid: cachedChat.$jid, options: {
            vcard: {
              ...fields,
            },
          },
        }))
        const notify = Presence.Messages.notifyVCardUpdated()
        connection.send(notify)
      } catch (e) {
      }
    }
  }, [cachedChat, dispatch])

  const onSaveChatName = async (text: string) => {
    if (cachedChat?.type === 'groupchat' && cachedChat?.vcard) {
      const msg = VCard.Messages.setVCard({
        ...cachedChat.vcard,
        to: cachedChat.$jid,
        nickname: text,
      })
      const oldName = cachedChat.vcard?.nickname || cachedChat.name || ''
      dispatch(updateChat({
        chatJid: cachedChat.$jid, options: {
          name: text,
          vcard: {
            ...activeChat?.vcard,
            nickname: text
          },
        },
      }))
      try {
        await connection.send(msg)
        const notify = Presence.Messages.notifyVCardUpdated()
        connection.send(notify)
      } catch (e) {
        dispatch(updateChat({
          chatJid: cachedChat.$jid, options: {
            name: oldName,
            vcard: {
              ...activeChat?.vcard,
              nickname: oldName
            },
          },
        }))
      }
    }
    if (cachedChat?.type === 'chat' && cachedChat?.vcard) {
      setNickName(cachedChat.$jid, text).then((result) => {
        dispatch(updateChat({chatJid: cachedChat.$jid, options: {nick: text}}))
      }).catch(() => {
        showNotify({
          message: 'Error. Please try again later.',
        })
      })
    }
  }

  const actionBeforeCropClosed = useCallback((blob: Blob): Promise<void> => {
    return new Promise(async (res) => {
      try {
        const {url, thumbnail} = await setMUChatAvatar({
          room: cachedChat?.$jid + '',
          avatar: blob,
        })
        if (cachedChat?.vcard) {
          const msg = VCard.Messages.setVCard({
            ...cachedChat?.vcard,
            to: cachedChat?.$jid,
            url,
            thumbnail,
          })
          await connection.send(msg)
          const notify = Presence.Messages.notifyVCardUpdated()
          connection.send(notify)
          const image = new Image()
          image.src = url
          image.onload = () => {
            if (cachedChat.vcard) {
              dispatch(updateChat({
                chatJid: cachedChat.$jid + '', options: {
                  vcard: {
                    ...cachedChat.vcard,
                    url,
                    thumbnail,
                  },
                },
              }))
            }
            res()
          }
          image.onerror = () => {
            res()
          }
        }
      } catch (e) {
        console.log(e) // eslint-disable-line no-console
        res()
      }
    })
  }, [cachedChat, dispatch])

  const onPhotoAnimationEnd = () => {
    setAllowPhotoEdit(bigPhoto)
  }

  const onActionMenuClick = (action: string) => {
    switch (action) {
      case MENU_ACTIONS.CLEAR_ALL_MESSAGES:
        handleClearAllMessage()
        break
      case MENU_ACTIONS.DELETE_AND_LEAVE_CHAT:
        handleLeaveChat()
        break
      case MENU_ACTIONS.BLOCK_CONTACT:
      case MENU_ACTIONS.UNBLOCK_CONTACT:
        toggleBlockContact()
        break
      case MENU_ACTIONS.DELETE_USER:
        unsubscribeHandler()
        break
      case MENU_ACTIONS.MUTE_CHAT:
      case MENU_ACTIONS.UNMUTE_CHAT:
        const mute = action === MENU_ACTIONS.MUTE_CHAT
        muteChat({jid: cachedChat?.$jid || '', mute})
        break
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }

  const handleActionMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    const position = {
      x: e.pageX,
      y: e.pageY,
    }
    const list: IContextMenuList[] = [{
      text: cachedChat?.notifyMuted ? 'ACTION.UNMUTE_CHAT' : 'ACTION.MUTE_CHAT',
      action: cachedChat?.notifyMuted ? MENU_ACTIONS.UNMUTE_CHAT : MENU_ACTIONS.MUTE_CHAT,
      icon: 'chat-mute',
    }]
    if (cachedChat?.type === 'chat' && !isMyTeam(cachedChat?.groups || [])) {
      list.push(
        {
          text: isBlocked ? 'menu_item_unblock' : 'action_block_contact',
          action: isBlocked ? MENU_ACTIONS.UNBLOCK_CONTACT : MENU_ACTIONS.BLOCK_CONTACT,
          SvgElement: BlockImage,
        },
        {
          text: 'CONTACTS.DELETE_USER', action: MENU_ACTIONS.DELETE_USER,
          SvgElement: TrashImage,
        })
    } else if (cachedChat?.type === 'groupchat') {
      if (isOwner) {
        list.push({
          text: 'CHAT.CLEAR_ALL_MESSAGE',
          action: MENU_ACTIONS.CLEAR_ALL_MESSAGES,
          SvgElement: ClearImage,
        })
      }
      if (!isMyTeam(cachedChat?.groups || [])) {
        list.push({
          text: 'GROUP_CHAT.DELETE_LEAVE',
          action: MENU_ACTIONS.DELETE_AND_LEAVE_CHAT,
          SvgElement: TrashImage,
        })
      }
    }

    showActionMenu({
      position,
      componentProps: {
        list: list.map(item => ({
          ...item,
          text: formatMessage({id: item.text}),
        })),
        onClick: onActionMenuClick,
      },
    })
    setActionMenuActive(true)
  }

  const addMembers = async (chats: Chat[]) => {
    hideInviteParticipants()
    if (!chats.length) {
      return
    }
    const newMembers: IGroupChatMember[] = chats.map(user => ({
      jid: user.$jid || '',
      affiliation: 'member',
      muted: false,
    }))
    dispatch(updateMembers({chatJid: cachedChat?.$jid || '', members: newMembers}))
    try {
      await addToRoom({
        roomJid: cachedChat?.$jid || '',
        participantJids: newMembers.map(member => getChatId(member.jid)),
      })
    } catch (e) {
      dispatch(removeMembers({
        chatJid: cachedChat?.$jid || '',
        memberJids: newMembers.map(member => member.jid),
      }))
    }
  }

  const onParticipantsSearchFocus: FocusEventHandler<HTMLElement> = (event) => {
    const target = event.target
    let parent = target.offsetParent
    let top = target.offsetTop
    const boxParent = contextBoxRef.current
    if (!boxParent || !(parent instanceof HTMLElement)) {
      return
    }
    top += parent.offsetTop
    while (parent instanceof HTMLElement && parent.offsetParent &&
    (parent.offsetParent !== boxParent && parent.offsetParent !== boxParent.offsetParent)) {
      parent = parent.offsetParent
      top += parent instanceof HTMLElement ? parent.offsetTop : 0
    }
    const offset = parent === boxParent ? 10 : boxParent.offsetTop + 4
    boxParent.scrollTo({
      top: top - offset,
      behavior: 'smooth',
    })
  }

  const onOpenChat = (user: IChatMember) => {
    if (privateChats[user.jid]) {
      setActiveChatId(user.jid)
    }
    hide()
  }

  const getGroupType = useMemo(() => {
    const message = {
      id: '',
    }
    switch (cachedChat?.groupType) {
      case 'private':
        message.id = 'GROUP_CHAT.PRIVATE'
        break
      case 'public':
        message.id = 'GROUP_CHAT.PUBLIC'
        break
      default:
        return ''
    }

    return formatMessage(message)
  }, [formatMessage, cachedChat?.groupType])

  const onHide = () => {
    setBigPhoto(false)
    setAllowPhotoEdit(false)
  }

  return <TabModal
    ref={tabModalRef}
    className={classNames(className)}
    width={487}
    onHide={onHide}
  >
    <div className={titleStyles.modalTitle}>
      <div className={styles.menuBox}>
        {(cachedChat?.$subscription === 'both' || cachedChat?.type === 'groupchat')
          && !isTeamChannel(cachedChat)
          && <IconButton
            onClick={toggleFavorites}
          >{isFavorite ? <StarImage className={styles.fillImage}/> : <StarEmptyImage/>}</IconButton>}
        <span className={styles.divider}><DividerImage/></span>
        <IconButton
          onClick={handleActionMenu}
        ><i className={'chat-dots'}/></IconButton>
        <IconButton onClick={hide}><i className={'chat-cross'}/></IconButton>
      </div>
    </div>
    <div
      ref={contextBoxRef}
      className={modalTitleStyles.context}>
      <div className={styles.section}>
        <div
          className={classNames(styles.animBox, bigPhoto && styles.big, !bigPhoto && styles.active)}
          onClick={() => setBigPhoto(true)}
          onTransitionEnd={onPhotoAnimationEnd}
        >
          {!allowPhotoEdit && <Avatar
            className={classNames(styles.avatar, bigPhoto && styles.big)} src={avatarUrl}
            name={cachedChat ? getNameOrNick(cachedChat) : undefined}
            onClick={() => setBigPhoto(true)}
          />}
          {allowPhotoEdit && <PhotoEditor
            imageSize={{
              width: screenWidth > MAX_AVATAR_SIZE + 2 * CONTAINER_PADDINGS ?
                MAX_AVATAR_SIZE : screenWidth - 2 * CONTAINER_PADDINGS, height: 'auto',
            }}
            className={styles.photoEditor}
            url={avatarUrl}
            text={cachedChat ? getFirstChar(getNameOrNick(cachedChat) || '').toUpperCase() : undefined}
            deletePhoto={deleteAvatar}
            canEdit={isOwner}
            actionBeforeCropClosed={actionBeforeCropClosed}
          />}
          {bigPhoto ? <Field
            textStyle={{fontWeight: 700}}
            moveWithoutIcon={false}
            className={classNames(styles.inputNameEdit)}
            text={cachedChat?.name ? getNameOrNick(cachedChat) : ''}
            button={(isOwner || (cachedChat?.type === 'chat' && cachedChat.$subscription === 'both'))
            && bigPhoto
            && !isTeamChannel(cachedChat)
              ? 'edit' : 'button'}
            minLength={2}
            maxLength={32}
            onSave={onSaveChatName}
            onClick={!isTeamChannel ? () => setBigPhoto(true) : undefined}
          /> : <>
            <div className={styles.infoGroup}>
              <span className={styles.groupNameText}>{cachedChat?.name ? getNameOrNick(cachedChat) : ''}</span>
              {cachedChat?.type === 'groupchat' && <FormattedMessage id={'CHAT.PARTICIPANTS'}>
                {txt => <span className={styles.infoDesc}>{txt}: {cachedChat?.members?.length || 0}</span>}
              </FormattedMessage>}
            </div>
            <IconButton
              className={styles.settinsBtn}
              onClick={() => setBigPhoto(true)}
            ><SettingsImage/></IconButton>
          </>}
        </div>
        {
          bigPhoto && cachedChat?.type === 'groupchat' && <Field
            moveWithoutIcon={false}
            button={canManage && (isOwner || cachedChat?.groupType === 'public') ? 'next' : 'button'}
            onNext={() => showTypeChat()}
          ><p className={styles.fieldText}>
            {formatMessage({id: 'group_type'})}
            <span className={styles.groupTypeText}>{getGroupType}</span>
          </p></Field>
        }
        {cachedChat?.type === 'chat' && bigPhoto && <Field
          textIcon={'ID'}
          text={getChatId(activeChatJid || '')}
        />}
      </div>
      {isChannelConsultant(activeChat) || isChannelCustomer(activeChat) ? <div className={styles.channelDescription}>
            {isChannelConsultant(activeChat)
              ? <>{formatMessage({id: 'my_consultants_channel_description'})}</>
              : <>{formatMessage({id: 'my_customers_channel_description'})}</>
            }
        </div> : null}
      <TabPanel
        className={styles.tabPanel}
        tabs={tabs}
        onChange={setActiveTab}
      />
      {activeTab === TABS_ACTIONS.PARTICIPANTS && <Participants
        chat={cachedChat}
        forceHideContextMenu={true}
        fixHeight={true}
        onSearchFocus={onParticipantsSearchFocus}
        onAddParticipants={showInviteParticipants}
        onOpenChat={onOpenChat}
      />}
      {activeTab === TABS_ACTIONS.FILES && <ChatFiles
        chat={cachedChat}
        onFileShow={() => hide()}
      />}
    </div>
    <ActionMenu/>
    {visibleInviteParticipants && <SelectChatsModal
      title={formatMessage({id: 'CHAT.INVITE_PARTICIPANTS'})}
      chats={notMembers}
      showCancelButton={true}
      hide={hideInviteParticipants}
      onAdd={addMembers}
    />}
    {
      visibleTypeChat && <GroupChatType
        showCancelButton={true}
        hide={hideTypeChat}
      />
    }
    <ConfirmModal/>
  </TabModal>
})

export default ChatInfo
