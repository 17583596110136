import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/ru';

dayjs.extend(localizedFormat)
dayjs.extend(duration)

function getDate(timestamp: number | string) {
  return dayjs(isNaN(+timestamp)
    ? timestamp
    : (`${timestamp}`.length > 13 ? Math.trunc(parseInt(''+ timestamp) / 1000) : parseInt('' + timestamp))
  )
}
export function getLastTime(timestamp: number | string) {
  const date = getDate(timestamp)
  return date.format('LT')
}

export function getLastMessageTimeStr(timestamp: number | string) {
  const date = getDate(timestamp)

  if (dayjs().diff(date, 'd') === 0) {
    return date.format('LT')
  }
  if (dayjs().diff(date, 'w') === 0) {
    return date.format('ddd')
  }

  return date.format('L');
}

export function getDayFormat(timestamp: number | string) {
  const date = getDate(timestamp)
  return date.format('MMM D, YYYY')
}

export function getFullDateFormat(timestamp: number | string) {
  const date = getDate(timestamp)
  return date.format('MMMM D, YYYY HH:mm:ss')
}

export function formatMinuteTime(seconds: number) {
  return dayjs.duration(seconds, 's').format('mm:ss')
}

export function timeFormat(then:any, locale:any) {
  if (then.toString().includes('Z')) {
    then = Date.parse(then).valueOf();
  }
  const dateInt = parseInt(then);
  const date = dateInt > 1000000000000000 ? dateInt / 1000 : dateInt;

  const th = new Date(date);
  return th.toLocaleTimeString(locale, {hour12: false, hour: '2-digit', minute: '2-digit'});
}

export const isToday = (timestamp: number) => {
  let currentDate = Math.trunc(parseInt('' + new Date().getTime()))
  let checkDate = Math.trunc(parseInt('' + new Date(timestamp).getTime()) / 1000)
  return new Date(currentDate).setHours(0, 0, 0, 0) === new Date(checkDate).setHours(0, 0, 0, 0)
}

export const areDiffDates = (dateNew: number, dateOld: number) => {
  let dateNewVal = Math.trunc(parseInt('' + new Date(dateNew).getTime()) / 1000)
  let dateOldVal = Math.trunc(parseInt('' + new Date(dateOld).getTime()) / 1000)
  return new Date(dateNewVal).setHours(0, 0, 0, 0) === new Date(dateOldVal).setHours(0, 0, 0, 0)
}
