import request from './request';
import {MessageType} from '@/interfaces/YC';
import {AxiosResponse} from 'axios';
import {getChatId} from '@/utils/chats';
import {Chat, ChatFile, GroupCategories} from '@/store/chats/chats';
import {BlockedUser} from '../store/blockList/blockList';
import {StatusOK} from '@/api/interfaces';

export interface IVcard {
  nickname: string,
  thumbnail?: string,
  url?: string,
  jabberId?: string,
}

export interface IContact {
  ask: string,
  groups: string[],
  jid: string,
  name: string,
  nick: string,
  resources: {},
  subscription: 'both' | 'from',
  vcard: IVcard,
}

export interface IRooms {
  room: string,
  title: string,
  type: 'private',
  vcard: string,
  mute: boolean,
  groups: GroupCategories[]
}

export interface IMessage {
  id: string,
  peer: string,
  from: string,
  to: string,
  state: 'seen' | 'sent',
  timestamp: number,
  text: string,
  type: MessageType
}

export interface IHistoryMessage {
  id: string,
  seen: boolean,
  text: string
  timestamp: number,
  type: MessageType,
  uid: string,
  url: string,
  xml: string
}

export type IThreadMessage = IMessage & {
  uid: string
}

export interface IThreads {
  contacts: IContact[],
  messages: {
    items: IThreadMessage[] | null,
    itemsCount: number,
    limit: number
  },
  rooms: {
    items: IRooms[]
  },
  notifyMute: string[] | null,
  vcard: {
    nickname?: string,
    thumbnail?: string,
    url?: string
  }
  hints: {
    initialHints: string[] | null
    shownHints: string[] | null
  }
}

export const threads = (): Promise<IThreads> => {
  return request('get', '/api/user/account/chat/history/threads?no_xml=true')
    .then(data => data.data.data)
}

interface IMessagesRequestBase {
  id: string,
  isRoom: boolean,
  limit?: number,
}

interface IMessagesRequestStart extends IMessagesRequestBase {
  start?: number
  end?: never
}

interface IMessagesRequestEnd extends IMessagesRequestBase {
  start?: never
  end?: number
}

type IMessagesRequest = IMessagesRequestStart | IMessagesRequestEnd

const getDataFromStatusOK = (data: any) => {
  if (data.status === 'ok') {
    return data
  }
  return Promise.reject(data?.error || '')
}

export const getMessages = ({
                              id,
                              isRoom = false,
                              limit = 15,
                              end,
                              start,
                            }: IMessagesRequest): Promise<IHistoryMessage[]> => {

  return request('get', '/api/user/account/chat/history', {
    limit,
    [isRoom ? 'withRoom' : 'with']: id,
    end,
    start,
  })
    .then(data => {
      return data?.data?.data.messages.items
    })
}

interface ICreateRoomProps {
  title: string,
  participants: string[],
}

interface INewRoom {
  room: string,
  status: 'ok',
}

export const createRoom = ({title, participants}: ICreateRoomProps): Promise<INewRoom> => {
  return request('post', '/api/group/create',
    {
      title,
      participants,
    },
  ).then((data) => data?.data)
}

interface ISetMUChatAvatarProps {
  room: string,
  avatar: Blob
}

interface ISetMUChatAvatar {
  thumbnail: string,
  url: string
}

export const setMUChatAvatar = ({
                                  room,
                                  avatar,
                                }: ISetMUChatAvatarProps): Promise<ISetMUChatAvatar> => {
  const formData = new FormData()
  formData.append('room', getChatId(room))
  formData.append('avatar', avatar)
  return request('post', '/api/group/avatar', formData).then(data => {
    return data.data
  })
}

export interface MutedUser {
  jid: string,
  mute: boolean
}

export const getMutedUsers = (roomId: string): Promise<MutedUser[]> => {
  return request('get', '/api/group/mute', {
    muc: roomId,
  }).then(data => data.data.map((user: { jid: string, mute: 'true' | 'false' }) => ({
    jid: user.jid,
    mute: user.mute === 'true',
  })))
}

interface MuteUserProps {
  jid: string,
  roomId: string
}

export const muteUser = ({jid, roomId}: MuteUserProps): Promise<AxiosResponse> => {
  return request('post', '/api/group/mute/update', {
    jid: jid,
    muc: roomId,
    mute: 'true',
  })
}

export const unmuteUser = ({jid, roomId}: MuteUserProps): Promise<AxiosResponse> => {
  return request('post', '/api/group/mute/update', {
    jid: jid,
    muc: roomId,
    mute: 'false',
  })
}

interface AdminUserProps {
  jid: string,
  roomId: string
}

export const makeAdmin = ({jid, roomId}: AdminUserProps): Promise<AxiosResponse> => {
  return request('post', '/api/group/admin/add', {
    jid: jid,
    muc: roomId,
  })
}

export const unmakeAdmin = ({jid, roomId}: AdminUserProps): Promise<AxiosResponse> => {
  return request('post', '/api/group/admin/remove', {
    jid: jid,
    muc: roomId,
  })
}

interface AddToRoomProps {
  participantJids: string[],
  roomJid: string,
  referrer?: string
}

export const addToRoom = ({participantJids, roomJid, referrer}: AddToRoomProps): Promise<StatusOK> => {
  return request('post', `/api/group/invite`, {
    participants: participantJids,
    room: getChatId(roomJid),
    referrer
  }).then(({data}) => {
    return getDataFromStatusOK(data)
  })
}

export const removeFromRoom = ({participantJids, roomJid}: AddToRoomProps): Promise<AxiosResponse> => {
  return request('post', `/api/group/kick`, {
    participants: participantJids,
    room: getChatId(roomJid),
    title: roomJid,
  })
}

export const addRoomToFavorites = (jid: string): Promise<AxiosResponse> => {
  return request('post', '/api/group/pin', {
    chat: jid,
  })
}

export const removeRoomFromFavorites = (jid: string): Promise<AxiosResponse> => {
  return request('post', '/api/group/unpin', {
    chat: jid,
  })
}

export const leaveChat = (chat: Chat): Promise<AxiosResponse> => {
  return request('post', '/api/group/leave', {
    room: getChatId(chat.$jid),
    title: chat.name,
  })
}

export const getTypeChat = (chat: Chat): Promise<AxiosResponse> => {
  return request('post', '/api/group/type', {
    muc: getChatId(chat.$jid),
  })
}

export const setTypeChat = (chat: Chat, isPublic: boolean): Promise<AxiosResponse> => {
  return request('post', '/api/group/type/update', {
    muc: getChatId(chat.$jid),
    isPublic: isPublic
  })
}

interface BlockedUserApi {
  avatar: string
  vcard: string
  jid: string
}

export const getBlockList = (): Promise<BlockedUser[]> => {
  return request('get', '/api/user/account/block-list/get')
    .then(data => {
        const users: BlockedUserApi[] = data.data
        return users.map(({avatar, vcard, jid}) => ({
          avatar,
          name: vcard,
          jid,
        }))
      },
    )
}

export const clearAllMessages = (roomId: string): Promise<AxiosResponse> => {
  return request('post', '/api/user/account/group/delete-all-messages', {
    Uid: roomId,
  })
}


type FileListProps = {
  jid: string,
  isRoom: boolean,
  limit?: number,
  offset?: number
}

export const getFileList = ({jid, isRoom, limit = 10, offset = 0}: FileListProps): Promise<ChatFile[]> => {
  return request('get', '/api/user/profile/files/get', {
    ...(isRoom ? {withRoom: jid} : {with: jid}),
    limit,
    offset,
  }).then(data => data.data)
}

export const setNickName = (username: string, nickName: string): Promise<AxiosResponse> => {
  return request('post', '/api/user/account/nickname/set', {username, nickName})
}


interface CheckMuc {
  name: string,
  status: 'ok'
}

export const checkMuc = (muc: string): Promise<CheckMuc> => {
  return request('get', '/api/group/check', {
    muc
  }).then(({data}) => {
    return getDataFromStatusOK(data)
  })
}

interface MuteNotifyProps {
  jid: string,
  mute: boolean
}

export const muteNotify = ({jid, mute}: MuteNotifyProps): Promise<StatusOK> => {
  return request('post', '/api/notify/mute', {
    jid,
    mute
  }).then(({data}) => {
    return getDataFromStatusOK(data)
  })
}

