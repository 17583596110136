import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '@/store';

export interface IHints {
  initialHints: string[]
  shownHints: string[]
}

const initialState: IHints = {
  initialHints: [],
  shownHints: []
}

const hints = createSlice({
  name: 'hints',
  initialState,
  reducers: {
    setHints(state, { payload }: PayloadAction<Partial<IHints>>) {
      const { initialHints, shownHints } = payload
      if (initialHints) {
        state.initialHints = initialHints
      }
      if (shownHints) {
        state.shownHints = shownHints
      }
    },
    addShownHint(state, { payload }: PayloadAction<string>) {
      state.shownHints.push(payload)
    },
    removeInitialHint(state, { payload }: PayloadAction<string>) {
      state.initialHints = state.initialHints.filter(hint => hint === payload)
    }
  }
})

export const getInitialHints = (state: RootState) => new Set(state.hints.initialHints)
export const getShownHints = (state: RootState) => new Set(state.hints.shownHints)
export const {
  setHints,
  addShownHint,
  removeInitialHint
} = hints.actions
export default hints.reducer
