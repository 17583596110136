import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '@/store';
import {
  ICallMessageType,
  MessageType,
  MessageTypeSet
} from '@/interfaces/YC';
import { Sticker } from '@/store/stickers/stickers';
import { v4 as uuid } from 'uuid'
import { removeFile } from '@/store/chats/chats';
import { isFileMessage } from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import { INews } from '@/store/news/news'
import {
  areDiffDates,
  isToday
} from '@/utils/dateutils';
import { findLastChatMessage } from '@/utils/messages';

export type IMessageStatus = 'sent' | 'received' | 'displayed' | 'sending'

interface ITimestamp {
  timestamp: number
}

export interface IDateMessage {
  id?: never
  type: 'date'
  date: Date
}

export interface IBreakMessage extends ITimestamp {
  id: string
  type: 'break'
}

export interface IMarkMessage {
  id: string
  text: string
  type: 'mark'
}

export interface IMessage extends ITimestamp {
  id: string,
  type: MessageType,
  from: string,
  to: string,
  thread?: string,
  status?: IMessageStatus
  replyMessage?: IMessage
  forwardedFrom?: string
}

export type ShowedMessage = IMessage | IBreakMessage | IDateMessage

export interface ITextMessage extends IMessage {
  text: string,
  isTranslating: boolean,
  isTranslated: boolean,
  textTranslate: string
}

export interface IImageMessage extends IMessage {
  image: {
    url: string,
    thumbnail: string
  }
}

export interface IFileFields {
  mimetype: string,
  name: string,
  size: number,
  url: string,
  token: string
}

export interface IFileMessage extends IMessage {
  file: IFileFields,
  uploaded: number | null,
  downloaded: number | null,
}

export interface IAudioMessage extends IMessage {
  url: string
  name?: string
}

export interface IVideoMessage extends IMessage {
  url: string
  thumbnail?: string
  uploaded: number | null,
  name?: string
  height?: number
}

export interface IStickerMessage extends IMessage {
  sticker: Partial<Omit<Sticker, 'id'>> & {
    id: string
  }
}

export interface ICallMessage extends IMessage {
  call?: {
    type: ICallMessageType,
    duration: number
  }
}

export interface IMessageItem {
  messages: ShowedMessage[],
  isLoaded: boolean,
  isFullLoaded: boolean,
  replyMessage: IMessage | null,
}

export interface IMessagesItem {
  [key: string]: IMessageItem
}

export interface IMessages {
  messages: IMessagesItem,
}

interface UpdateWithBreakProps {
  chatJid: string,
  messages: IMessage | IMessage[]
  breakId?: string
  insert?: 'after' | 'before'
}

const initialState: IMessages & {
  lastMessage: IMessage | null
  lastMessageBeforeOffline: IMessage | null
} = {
  messages: {},
  lastMessage: null,
  lastMessageBeforeOffline: null,
}

const createIfNotExist = (state: Draft<IMessages>, jid: string) => {
  if (!state.messages[jid]) {
    state.messages[jid] = {
      messages: [],
      isLoaded: false,
      isFullLoaded: false,
      replyMessage: null,
    }
  }
}

export const isBreakMessage = (message: ShowedMessage | INews | IMarkMessage): message is IBreakMessage => {
  return 'type' in message && message.type === 'break'
}

export const isNotBreakMessage = (message: IMessage | IBreakMessage): message is IMessage => {
  return message.type !== 'break'
}

export const isMessageOrNews =
  (message: ShowedMessage | IBreakMessage | INews | IMarkMessage): message is (IMessage | INews) => {
    return 'type' in message && MessageTypeSet.has(message.type)
  }

export const isMessage =
  (message: ShowedMessage | IBreakMessage | INews | IMarkMessage): message is IMessage => {
    return 'type' in message && MessageTypeSet.has(message.type) && message.type !== 'news'
  }

export const isDateMessage =
  (message: ShowedMessage | INews | IMarkMessage): message is IDateMessage => {
  return message.type === 'date'
}

const findLastMessage = (...messages: (IMessage | null)[]) => {
  return messages.reduce((acc, item) => ((acc?.timestamp || 0) < (item?.timestamp || 0)) ? item : acc, null)
}

const getLastMessageInList = (messageList: IMessages) => {
  const arrayMessages = Object.keys(messageList.messages)
    .map(key => messageList.messages[key].messages)
    .reduce((acc, item) => {
      acc.push(...item)
      return acc
    }, [] as IMessage[])
  return findLastMessage(...arrayMessages.filter(isMessage))
}

const messagesSort = (mes1: IBreakMessage | IMessage, mes2: IBreakMessage | IMessage) => {
  const timestamp1 = mes1.timestamp || 0
  const timestamp2 = mes2.timestamp || 0
  return timestamp1 - timestamp2
}

const createDateMessage = (message: { timestamp: number }): IDateMessage => {
  return {
    type: 'date',
    date: new Date(Math.trunc(message.timestamp) / 1000)
  }
}

const createMessageWithDate = (messages: (IMessage | IBreakMessage)[]): ShowedMessage[] => {
  let messageOld: IMessage | IBreakMessage | undefined
  const chatsWithDate: (ShowedMessage)[] = []

  messages.forEach((val, index) => {
    if (index === 0 && !isToday(val.timestamp)) {
      chatsWithDate.push(createDateMessage(val))
    }
    if (messageOld !== undefined && !areDiffDates(val.timestamp, messageOld.timestamp)) {
      chatsWithDate.push(createDateMessage(val))
    }
    chatsWithDate.push(val)
    messageOld = val
  })
  return chatsWithDate
}

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    set(state, { payload }: { payload: IMessages }) {
      state.messages = payload.messages
      state.lastMessage = findLastMessage(state.lastMessage, getLastMessageInList(state))
    },
    add(state, { payload }: { payload: { jid: string, message: IMessage } }) {
      const { jid, message } = payload
      createIfNotExist(state, jid)
      const lastMessage = findLastChatMessage(state.messages[jid].messages)
      if (lastMessage && areDiffDates(lastMessage.timestamp, message.timestamp)) {
        state.messages[jid].messages.push(createDateMessage(message))
      }
      state.messages[jid].messages.push(message)
      state.lastMessage = findLastMessage(state.lastMessage, message)
    },
    update(state, { payload }: { payload: { jid: string, messages: IMessage[] } }) {
      const { jid, messages } = payload
      createIfNotExist(state, jid)
      const oldMessages: (IMessage | IBreakMessage)[] = state.messages[jid].messages
        .filter(message => message.type !== 'date') as (IMessage | IBreakMessage)[]
      const messagesMap = new Map(oldMessages.map((message, index) => [message.id, { message, index }]))
      messages.forEach(message => {
        const oldMessage = messagesMap.get(message.id)
        if (oldMessage) {
          oldMessages[oldMessage.index] = {
            ...oldMessage.message,
            ...message,
          }
        } else {
          oldMessages.push(message)
        }
      })
      oldMessages.sort(messagesSort)
      state.messages[jid].messages = createMessageWithDate(oldMessages)
      state.lastMessage = findLastMessage(state.lastMessage, ...messages)
    },
    updateWithBreak(state, { payload }: { payload: UpdateWithBreakProps }) {
      const { messages, breakId, chatJid, insert = 'after' } = payload
      createIfNotExist(state, chatJid)
      const oldMessages: (IMessage | IBreakMessage)[] = state.messages[chatJid].messages
        .filter(message => message.type !== 'date') as (IMessage | IBreakMessage)[]
      if (breakId) {
        const oldBreakIndex = oldMessages.findIndex(message => message.id === breakId)
        if (~oldBreakIndex) {
          oldMessages.splice(oldBreakIndex, 1)
        }
      }
      const messagesArray = Array.isArray(messages) ? messages : [messages]
      messagesArray.sort(messagesSort)
      const messagesMap = new Map(oldMessages
        .map((message, index) => [message.id, { index, message }])
      )
      const addBreak = !messagesMap
        .has(messagesArray[insert === 'after' ? messagesArray.length - 1 : 0]?.id)
      messagesArray.forEach(msg => {
        if (messagesMap.has(msg.id)) {
          const { index, message } = messagesMap.get(msg.id)!
          oldMessages[index] = {
            ...message,
            ...msg,
          }
        } else {
          oldMessages.push(msg)
        }
      })
      if (addBreak && messagesArray.length > 0) {
        const breakMessage: IBreakMessage = {
          id: uuid(),
          type: 'break',
          timestamp: messagesArray[insert === 'after' ? messagesArray.length - 1 : 0].timestamp,
        }
        oldMessages.push(breakMessage)
        oldMessages.sort(messagesSort)
        if (insert === 'after') {
          const breakIndex = oldMessages.findIndex(message => message.id === breakMessage.id)
          if (~breakIndex) {
            breakMessage.timestamp = oldMessages[breakIndex + 1]?.timestamp || 0
          }
        }
      } else {
        oldMessages.sort(messagesSort)
      }
      state.messages[chatJid].messages = createMessageWithDate(oldMessages)
      const length = state.messages[chatJid].messages.length
      const lastMessage = state.messages[chatJid].messages[length - 1]
      if (lastMessage?.type === 'break') {
        state.messages[chatJid].messages.splice(length - 1, 1)
      }
      state.lastMessage = findLastMessage(state.lastMessage, ...messagesArray)
    },
    setReply(state, { payload }: { payload: { jid: string, replyMessage: IMessage | null } }) {
      const { jid, replyMessage } = payload
      createIfNotExist(state, jid)
      state.messages[jid].replyMessage = replyMessage
    },
    remove(state, { payload }: { payload: { jid: string, message: IMessage } }) {
      const { jid, message } = payload
      const index = state.messages[jid].messages.findIndex(item => item.id === message.id) || -1
      if (~index) {
        const [removedMessage] = state.messages[jid].messages.splice(index, 1)
        if (removedMessage === state.lastMessage) {
          state.lastMessage = findLastMessage(state.lastMessage, getLastMessageInList(state))
        }
      }
    },
    clear(state, { payload }: { payload: string }) {
      if (state.messages[payload]) {
        state.messages[payload].messages = []
        state.lastMessage = findLastMessage(state.lastMessage, getLastMessageInList(state))
      }
    },
    messagesWasLoaded(state, { payload }: { payload: { jid: string, value: boolean } }) {
      const { jid, value } = payload
      createIfNotExist(state, jid)
      state.messages[jid].isLoaded = value
    },
    messagesWasFullLoaded(state, { payload }: { payload: { jid: string, value: boolean } }) {
      const { jid, value } = payload
      createIfNotExist(state, jid)
      state.messages[jid].isFullLoaded = value
    },
    setLastMessageBeforeOffline(state, { payload }: { payload: IMessage | null }) {
      state.lastMessageBeforeOffline = payload
    },
    readMessagesInChat(state, { payload }: PayloadAction<string>) {
      if (!state.messages[payload]) {
        return
      }
      const messages = state.messages[payload].messages
      let readFlag = false
      const readMessages = messages.map(message => {
        if (!isMessage(message)) {
          return message
        }
        if (message.status !== 'displayed') {
          readFlag = true
        }
        message.status = 'displayed'
        return { ...message }
      })
      if (readFlag) {
        state.messages[payload].messages = readMessages
      }
    }
  },
})

export const {
  add,
  set,
  update,
  updateWithBreak,
  setReply,
  clear,
  messagesWasLoaded,
  messagesWasFullLoaded,
  setLastMessageBeforeOffline,
  readMessagesInChat
} = messages.actions


export const remove = createAsyncThunk('messages/removeMessage',
  ({ jid, message }: { jid: string, message: IMessage }, { dispatch }) => {
    dispatch(messages.actions.remove({ jid, message }))
    if (isFileMessage(message)) {
      dispatch(removeFile({ chatJid: jid, url: message.file.url }))
    }
  })

export const getAllMessages = (state: RootState) => state.messages.messages
export const getMessages = (jid: string) => (state: RootState): IMessageItem | undefined => state.messages.messages[jid]
export const getReplyMessage = (jid: string) => (state: RootState) => state.messages.messages[jid]?.replyMessage
export const getLastMessage = (state: RootState) => state.messages.lastMessage
export const getLastMessageBeforeOffline = (state: RootState) => state.messages.lastMessageBeforeOffline
export default messages.reducer
